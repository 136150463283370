import PropTypes from 'prop-types';

import FileToDownload from '@/src/howTo/components/fileToDownload/FileToDownload.storyblok';
import FileWithPreview from '@/src/howTo/components/fileWithPreview/FileWithPreview.storyblok';
import HowToSideBar from '@/src/howTo/components/sideBar/HowToSideBar.storyblok';
import VimeoVideo from '@/src/howTo/components/VimeoVideo';
import BulletListWithIconStoryblok from '@/src/landing/components/BulletListWithIcon.storyblok';
import CallToAction from '@/src/landing/components/CallToAction/CallToAction';
import {HeroSignupS} from '@/src/landing/components/HeroWithEmail/HeroSignupS';
import MedicalLearning from '@/src/landing/components/MedicalLearning/MedicalLearning';
import UserReviews from '@/src/landing/components/UsersReviews/UserReviews';
import WeAreTheBest from '@/src/landing/components/WeAreTheBest/WeAreTheBest';
import {CategoriesSection} from '@/src/promoSections';
import {Sponsors} from '@/src/promoSections/components/Sponsors';
import SimpleCollapseStoryblok from '@/src/ui/Collapse/components/SimpleCollapse.storyblok';
import {HStack} from '@/src/ui/direction/Hstack';
import {VStack} from '@/src/ui/direction/Vstack';
import GradientHeadLine from '@/src/ui/gradientHeadLine/components/GradientHeadLine.storyblok';
import Grid from '@/src/ui/grid/Grid.storyblok';
import {Image} from '@/src/ui/Image/Image.storyblok';
import Spacer from '@/src/ui/spacer/components/Spacer.storyblok';

import AboutMedyoucateSection from '../../landing/components/AboutMedyoucateSection.storyblok';
import TextImageGrid from '../../landing/components/ForWhom.stoyblok';
import HeroWithEmail from '../../landing/components/HeroWithEmail/HeroWithEmail';
import LearningFromLeaders from '../../landing/components/LearningFromLeaders/LearningFromLeaders';
import WhyBestList from '../../landing/components/WhyBestList.storyblok';
import FaqSection from '../../promoSections/components/FaqSection/FaqSection.storyblok';
import FeaturesSection from '../../promoSections/components/FeaturesSection.storyblok';
import HeroSectionLayout from '../../promoSections/components/HeroSectionLayout/HeroSectionLayout.storyblok';
import InfoSection from '../../promoSections/components/infoSection/InfoSection.storyblok';
import PromoLinks from '../../promoSections/components/PromoLinks/PromoLinks.storyblok';
import SpecialField from '../../promoSections/components/SpecialField.storyblok';
import TrustedSection from '../../promoSections/components/TrustedSection.storyblok';
import PopularCoursesSection from '../../promoSections/containers/PopularCourses/PopularCoursesContainer.storyblok';
import PricingSection from '../../promoSections/containers/PricingSection/PricingSection.storyblok';
import TopContentCreatorsSection from '../../promoSections/containers/TopContentCreatorsSection/TopContentCreatorsContainer.storyblok';
import Button from '../../ui/button/components/Button.storyblock';
import Card from '../../ui/card/components/Card.storyblok';
import Link from '../../ui/link/Link.storyblok';
import SectionContainer from '../../ui/sectionContainer/components/SectionContainer.storyblok';
import Text from '../../ui/text/components/Text.storyblok';
import ViewWithSideBar from '../../ui/views/ViewWithSideBar/components/ViewWithSideBar.storyblok';
// storyblok initial components
import Page from './Page';
import Placeholder from './Placeholder';
import RichText from './RichText';

const Components = {
  page: Page,
  AboutMedyoucate: AboutMedyoucateSection,
  SpecialField: SpecialField,
  SectionContainer: SectionContainer,
  InfoSection: InfoSection,
  Link: Link,
  Button: Button,
  Text: Text,
  FeaturesSection,
  ViewWithSideBar: ViewWithSideBar,
  WhyWeBest: WhyBestList,
  PopularCoursesSection: PopularCoursesSection,
  PricingSection,
  TopContentCreatorsSection,
  TrustedSection,
  PromoLinks,
  FaqSection,
  HeroSectionLayout,
  RichText,
  GradientHeadLine,
  Spacer,
  HowToSideBar,
  FileWithPreview,
  FileToDownload,
  VimeoVideo,
  TextImageGrid,
  HeroWithEmail,
  LearningFromLeaders,
  CategoriesWithCourses: CategoriesSection,
  MedicalLearning,
  WeAreTheBestWithSideBar: WeAreTheBest,
  SimpleAccordion: SimpleCollapseStoryblok,
  BulletWithIcon: BulletListWithIconStoryblok,
  ReviewsCarousel: UserReviews,
  CallToActionSection: CallToAction,
  SignupEmailHero: HeroSignupS,
  Sponsors: Sponsors,
  Card: Card,
  Grid,
  Image,
  VStack,
  HStack,
};

const DynamicComponent = ({blok}) => {
  if (typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component];

    return <Component blok={blok} />;
  }

  return <Placeholder componentName={blok.component} />;
};

DynamicComponent.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default DynamicComponent;
