import {useEffect, useState} from 'react';

export const useTheme = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const darkMode = document.querySelector('.darkMode');
    if (darkMode) {
      setIsDarkMode(!!darkMode);
    }
  }, []);

  return {
    isDarkMode,
  };
};
