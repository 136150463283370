import cn from 'clsx';
import PropTypes from 'prop-types';
import {useCallback, useState} from 'react';
import {Collapse as CollapseFromLibrary} from 'react-collapse';

import ArrowIconWhite from '@/public/icons/arrorWhite.svg';
import ArroIcon from '@/public/icons/arrowDark.svg';
import {useTheme} from '@/src/hooks/useTheme';

import css from '../styles/collapse.module.css';

const INNER_PADDING = {
  DEFAULT: 'default',
  NO_PADDING: 'noPadding',
};

/**
 * Accordion to show and Hide content
 *
 * @param {JSX} children
 * @param {boolean} headTitle
 * @param {boolean} defaultIsOpen
 * @param innerPadding
 * @param isRoundedHeader
 * @return {JSX}
 */
const Collapse = ({
  children,
  headTitle,
  defaultIsOpen,
  innerPadding,
  isRoundedHeader,
}) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const {isDarkMode} = useTheme();

  const toggleIsOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <div className={css.mainWrapper}>
      <div
        className={cn(css.header, {
          [css.isRounded]: isRoundedHeader,
        })}
        onClick={toggleIsOpen}
      >
        <div
          className={cn(css.arrowIndicator, {
            [css.arrowIndicatorOpen]: isOpen,
          })}
        >
          {isDarkMode ? <ArrowIconWhite /> : <ArroIcon />}
        </div>
        {headTitle}
      </div>
      <CollapseFromLibrary isOpened={isOpen} theme={{collapse: css.content}}>
        <div className={css[innerPadding]}>{children}</div>
      </CollapseFromLibrary>
    </div>
  );
};

Collapse.INNER_PADDING = INNER_PADDING;

Collapse.propTypes = {
  children: PropTypes.node.isRequired,
  headTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  innerPadding: PropTypes.oneOf(Object.values(INNER_PADDING)).isRequired,
  defaultIsOpen: PropTypes.bool,
  isRoundedHeader: PropTypes.bool,
};

Collapse.defaultProps = {
  innerPadding: INNER_PADDING.DEFAULT,
};

export default Collapse;
