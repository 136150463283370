import DynamicComponent from '@/src/dynamicComponents/components/DynamicComponent';

import css from './stack.module.css';

export const VStack = ({blok}) => {
  const {gap, body} = blok;

  return (
    <div
      className={css.vStack}
      style={{
        gap: gap,
      }}
    >
      {body
        ? body.map((blok) => <DynamicComponent blok={blok} key={blok._uid} />)
        : null}
    </div>
  );
};
