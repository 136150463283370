import cn from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import DynamicComponent from '@/src/dynamicComponents/components/DynamicComponent';

import css from '../styles/Card.module.css';

const SIZE = {
  SMALL: 'small',
  regular: 'regular',
};

const BORDER_RADIUS = {
  SMALL: 'borderRadiusSmall',
  REGULAR: 'borderRadiusRegular',
};

const INNER_OFFSET = {
  NONE: 'padding0',
  REGULAR: 'paddingRegular',
  MEDIUM_EQUAL: 'paddingMediumEqual',
};

const Card = ({blok}) => {
  const {
    size = SIZE.regular,
    innerOffset = INNER_OFFSET.REGULAR,
    body,
    borderRadius = BORDER_RADIUS.REGULAR,
  } = blok;
  return (
    <div
      className={cn(css.card, css[size], css[borderRadius], css[innerOffset])}
    >
      {body
        ? body.map((blok) => <DynamicComponent blok={blok} key={blok._uid} />)
        : null}
    </div>
  );
};

Card.propTypes = {
  blok: PropTypes.object.isRequired,
};

Card.defaultProps = {
  blok: {},
};

export default Card;
