import cn from 'clsx';
import Link from 'next/link';
import PropTypes from 'prop-types';
import {createContext, memo, useContext, useEffect, useState} from 'react';

import LogoSvg from '@/public/images/logo.svg';
import WhiteLogoSvg from '@/public/images/whiteLoogo.svg';
import HowToMobileMenu from '@/src/header/containers/HowToMobileMenu';
import useIsMobile from '@/src/hooks/useIsMobile';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import {useTheme} from '@/src/hooks/useTheme';

import LogoSvgSmall from '../../../public/images/logo-small.svg';
import {SectionContainer} from '../../ui';
import css from '../styles/header.module.css';
import AuthorizedHeader from './AuthorizedHeader';
import UnauthorizedHeader from './UnauthorizedHeader';

export const StickyContext = createContext(false);

const MyMemoizedHeaderWrapper = memo(function HeaderWrapper({
  children,
}: {
  children: JSX.Element;
}) {
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const updateHeaderSticky = () => {
      if (window.scrollY === 0) {
        setSticky(false);
      } else {
        setSticky(true);
      }
    };

    window.addEventListener('scroll', updateHeaderSticky);

    updateHeaderSticky();

    return () => window.removeEventListener('scroll', updateHeaderSticky);
  }, []);

  return (
    <header
      className={cn(css.header, {
        [css.headerSticky]: isSticky,
      })}
    >
      <StickyContext.Provider value={isSticky}>
        {children}
      </StickyContext.Provider>
    </header>
  );
});

const HeaderInner = ({
  isHowToMenu,
  isRootPage,
  isAuthorized,
  userInitials,
  picture,
  user,
}) => {
  const {DISCOVER, INDEX_ROUTE} = useRoutePaths();
  const isSticky = useContext(StickyContext);
  const {isMaxWidth1100} = useIsMobile();
  const {isDarkMode} = useTheme();

  return (
    <SectionContainer>
      <div className={css.headerInner}>
        {isHowToMenu ? (
          <div className={css.howToWrapper}>
            <div>
              <Link
                href={isAuthorized ? DISCOVER.getLink() : INDEX_ROUTE.getLink()}
              >
                <a>
                  <LogoSvgSmall />
                </a>
              </Link>
            </div>
            <div>
              <HowToMobileMenu />
            </div>
          </div>
        ) : (
          <Link
            href={isAuthorized ? DISCOVER.getLink() : INDEX_ROUTE.getLink()}
          >
            <a className={css.logo} aria-label={'medyoucate logo'}>
              {isRootPage && !isSticky && !isMaxWidth1100 ? (
                <WhiteLogoSvg />
              ) : isDarkMode ? (
                <WhiteLogoSvg />
              ) : (
                <LogoSvg />
              )}
            </a>
          </Link>
        )}

        {isAuthorized ? (
          <AuthorizedHeader
            userInitials={userInitials}
            picture={picture}
            user={user}
            isRootPage={isRootPage && !isSticky}
          />
        ) : (
          <UnauthorizedHeader
            user={user}
            isMenuWithBg={isRootPage && !isSticky}
          />
        )}
      </div>
    </SectionContainer>
  );
};

const HeaderLayout = ({
  userInitials,
  picture,
  user,
  isAuthorized,
  isHowToMenu,
  isRootPage,
}) => {
  return (
    <>
      {!isRootPage && <div className={css.headerMargin} />}
      <MyMemoizedHeaderWrapper>
        <HeaderInner
          isRootPage={isRootPage}
          picture={picture}
          userInitials={userInitials}
          user={user}
          isAuthorized={isAuthorized}
          isHowToMenu={isHowToMenu}
        />
      </MyMemoizedHeaderWrapper>
    </>
  );
};

HeaderLayout.propTypes = {
  userInitials: PropTypes.string,
  picture: PropTypes.string,
  user: PropTypes.object,
  isAuthorized: PropTypes.bool.isRequired,
  isHowToMenu: PropTypes.bool,
};

HeaderLayout.defaultProps = {
  userInitials: '',
  isHowToMenu: true,
};

export default HeaderLayout;
