import {sbEditable} from '@storyblok/storyblok-editable';
import PropTypes from 'prop-types';
import React from 'react';

import DynamicComponent from '@/src/dynamicComponents/components/DynamicComponent';
import useIsMobile from '@/src/hooks/useIsMobile';

import css from './Grid.module.css';

const DEFAULT_ITEMS_IN_ROW = 4;

const getItemsPerRow = ({
  isMaxWidth320,
  isMaxWidth480,
  isMaxWidth768,
  isMaxWidth992,
  isMaxWidth1280,
  isMaxWidth1536,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
}) => {
  if (isMaxWidth320) {
    return xs || sm || md || lg || xl || xxl || DEFAULT_ITEMS_IN_ROW;
  }

  if (isMaxWidth480) {
    return sm || md || lg || xl || xxl || DEFAULT_ITEMS_IN_ROW;
  }

  if (isMaxWidth768) {
    return md || lg || xl || xxl || DEFAULT_ITEMS_IN_ROW;
  }

  if (isMaxWidth992) {
    return lg || xl || xxl || DEFAULT_ITEMS_IN_ROW;
  }

  if (isMaxWidth1280) {
    return xl || xxl;
  }

  if (isMaxWidth1536) {
    return xxl || DEFAULT_ITEMS_IN_ROW;
  }

  return xxl || DEFAULT_ITEMS_IN_ROW;
};

const Grid = ({blok}) => {
  const {gap, body, id, xxl, xl, lg, md, sm, xs} = blok;

  const {
    isMaxWidth320,
    isMaxWidth480,
    isMaxWidth768,
    isMaxWidth992,
    isMaxWidth1280,
    isMaxWidth1536,
  } = useIsMobile();

  const itemsPerRow = getItemsPerRow({
    isMaxWidth320,
    isMaxWidth480,
    isMaxWidth768,
    isMaxWidth992,
    isMaxWidth1280,
    isMaxWidth1536,
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
  });

  return (
    <div
      className={css.list}
      {...sbEditable(blok)}
      id={id}
      style={{
        gridTemplateColumns: `repeat(${itemsPerRow}, 1fr)`,
        gap: gap,
      }}
    >
      {body
        ? body.map((blok) => <DynamicComponent blok={blok} key={blok._uid} />)
        : null}
    </div>
  );
};

Grid.propTypes = {
  blok: PropTypes.object,
};

export default Grid;
